import i18n_translations from "../../components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Caminhos de Inspiração Instituto Chrisan e a Revolução pela Arte e Educação",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        title: "CHRISAN",
        subtitle: "Exalando vidas!",
        button: "Saiba mais",
      },
      COMP00003: {
        title: "ESPERANÇA, INCLUSÃO SOCIAL E TRANSFORMAÇÃO.",
        subTitle:
          `<p>Em 2012, nasceu um sonho audacioso e cheio de esperança na Baixada Fluminense, tencionando transformar vidas e contribuir para a construção de um futuro mais justo e igualitário. Esse sonho concretizado é o CHRISAN. Somos uma instituição comprometida com a redução da desigualdade social.</p>
          <p>Lutamos, diariamente, para oferecer oportunidades através da expressão artística e do aprendizado significativo. Hoje, nossa Escola de Artes é o epicentro dessa transformação, projetada para desencadear um processo de aprendizagem, capacitando cada indivíduo a moldar seu próprio destino.</p>
          <p>Somos reconhecidos pelo CMAS, CMDCA, CNEAS, CEBAS, além de ostentar, com honra, os selos DOAR e ONG Transparente (em fase de implementação). Esses selos são provas do nosso compromisso com a transparência, integridade e excelência em tudo o que fazemos.</p>
          <p><strong>Expandimos horizontes, enriquecemos perspectivas e moldamos futuros.</strong></p>
          `,
        button: "JUNTE-SE A NÓS E TRANSFORME REALIDADES.",
      },
      COMP00004: {
        title: "NOSSOS NÚMEROS",
        cards: [
          {
            top: "",
            span: "26",
            bottom: "Funcionários",
          },
          {
            top: "",
            span: "5",
            bottom: "Estagiários com bolsa + pagamento da faculdade",
          },
          {
            top: "Mais de",
            span: "20",
            bottom: "Voluntários",
          },
          {
            top: "Mais de",
            span: "6",
            bottom: "Anos de atuação",
          },
        ],
        button: "JUNTE-SE À NOSSA CAUSA! FAÇA A DIFERENÇA HOJE COM SUA DOAÇÃO",
      },
      COMP00005: {
        title: "DIRETRIZES DO INSTITUTO",
        cards: [
          {
            title: 'Missão',
            text: ' Existir promovendo a cidadania e o desenvolvimento social de pessoas em situação de vulnerabilidade social em sua totalidade.',
          },
          {
            title: 'Visão',
            text: 'Ser reconhecido como agente de transformação, valorização e capacitação pela comunidade, por agentes públicos e privados em todo o estado do Rio de Janeiro.',
          },
        ],
        button: "Doe agora"
      },
      COMP00008: {
        title: "ATIVIDADES DO INSTITUTO.",
        subTitle:
          `<p class='left'>Nossas atividades visam não apenas preencher lacunas, mas também criar oportunidades para o crescimento pessoal e comunitário. Algumas iniciativas que estão no centro de nossa missão:
          </p>
          <ul>
            <li><p>Todas as atividades do Instituto são 100% gratuitas</p></li>
            <li><p>Escola de Circo</p></li>
            <li><p>Escola de Capoeira</p></li>
            <li><p>Escola de Teatro</p></li>
            <li><p>Escola de Dança</p></li>
            <li><p>Escola de rádio, podcast e TV</p></li> 
            <li><p>Zumba</p></li>
            <li><p>Danças Urbanas</p></li>
            <li><p>Ginástica Funcional</p></li>
            <li><p>Projeto 60+</p></li>
            <li><p>Sarau de Poesias</p></li>
            <li><p>Acesso Gratuito e Regular:</p>Acreditamos na democratização do acesso à cultura, arte e educação.</li>
          </ul>
          <p>A arte e a educação são as ferramentas que moldam o amanhã.</p>
          <strong>TRANSFORMAMOS VIDAS COM ARTE E EDUCAÇÃO | INCLUSÃO EM AÇÃO | SEMEANDO ESPERANÇA</strong>
          `,
        button: "FAÇA PARTE DO NOSSO IMPACTO POSITIVO!",
      },
      COMP00017: {
        title: "<h2>LINHA DO TEMPO DO INSTITUTO</h2>",
        subtitle: `<p class="subtitle">Seja parte da história do Instituto Chrisan.<p>`,
        cards: [
          {
            title: "2016",
            text: "Lançamento do CHRISAN com projetos abrangentes, incluindo capoeira, artesanato, jiu-jitsu e aulas de reforço educacional."
          },
          {
            title: "2018",
            text: "Implementação do projeto de segurança alimentar e inclusão das atividades de zumba e teatro.",
          },
          {
            title: "2019",
            text: "Estabelecimento de uma parceria estratégica com o Instituto da Criança para a implantação do curso \"Espaço Cidadão\", ampliando nosso impacto educacional.",
          },
          {
            title: "2020",
            text: "Ano desafiador com o início da pandemia, mobilizando esforços para arrecadar 12 toneladas de alimentos e iniciar uma imersão profunda em nossas documentações internas.",
          },
          {
            title: "2021",
            text: "Expandimos de 8 para 21 projetos durante a pandemia. O diretor Otávio Oliveira é eleito Empreendedor Social do Ano no Brasil, em uma auditoria conduzida pelo Instituto da Criança. Arrecadação adicional de 21 toneladas de alimentos para combater a fome. Conquista da primeira emenda parlamentar para fortalecer nossas iniciativas.",
          },
          {
            title: "2022",
            text: "Enfrentamos um ano difícil ao ficar sem sede. Conquista da certificação CEBAS, reconhecendo nossa dedicação e eficiência nas atividades sociais.",
          },
          {
            title: "2023",
            text: "Ano de renovação e conquistas com a obtenção de uma nova sede para o CHRISAN.\nImplementação do programa para estagiários.",
          },
        ],
      },
      COMP00018: {
        title: "Valores",
        altText: "Foto de",
        cards: [
          {
            title: "Ser Gente que Gosta de Gente",
            content: "Celebramos a humanidade em sua diversidade, promovendo relações baseadas no respeito, compaixão e empatia.",
          },
          {
            title: "Ética",
            content: "Pautamos nossas ações em princípios éticos sólidos, garantindo que cada iniciativa seja conduzida com integridade e responsabilidade.",
          },
          {
            title: "Transparência",
            content: "Mantemos canais abertos de comunicação, garantindo que a transparência seja a base de todas as interações, fortalecendo a confiança em nossa comunidade."
          },
          {
            title: "Compromisso",
            content: "Estamos comprometidos com a causa que abraçamos, dedicando nossos esforços para criar impacto positivo e duradouro."
          },
          {
            title: "Honestidade",
            content: "Agimos com honestidade e franqueza, reconhecendo que a verdade é essencial para o crescimento e a confiança mútua."
          },
          {
            title: "Integridade",
            content: "Mantemos padrões elevados de integridade em todas as nossas operações, garantindo que nossas ações reflitam nossos valores fundamentais."
          },
          {
            title: "Foco no Resultado",
            content: "Buscamos não apenas fazer a diferença, mas alcançar resultados tangíveis que melhorem a vida daqueles que atendemos."
          },
        ],
        subtitle: "Compromisso com a Transparência e Excelência",
        button: "Doe agora",
      },
      COMP00020: {
        title: "METAS E OBJETIVOS",
        subTitle: `No CHRISAN, nossa visão para o futuro é tão ousada quanto nossa missão. Buscamos ampliar nossa família, proporcionando acesso às oportunidades transformadoras oferecidas pelo CHRISAN Queremos ser o lar virtual de 30.000 mentes curiosas, prontas a explorar, aprender e crescer, ampliar nossa base de apoiadores, cultivando 100 doadores dedicados que compartilham nossa visão de uma comunidade mais justa e igualitária.<br><br>
        <strong>Visamos ser um símbolo de esperança, crescimento e realização.</strong>`,
        button: "Doe agora",
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>Sua doação é muito importante para nós e faz toda a diferença. O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Quer sua doação seja grande ou pequena, saiba que ela é uma prova do seu compromisso com um mundo melhor para todos. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>CHRISAN</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>No Clube do Bem da Propago  você irá economizar dinheiro, tornar seu dia a dia mais prático e o melhor de tudo, sua economia pode virar uma doação!</p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: 'Beleza e bem-estar! -',
            discount: 'Até 80%'
          },
          {
            area: 'Lazer -',
            discount: 'Até 50% OFF'
          },
          {
            area: 'Restaurantes -',
            discount: 'Até 35% OFF'
          },
          {
            area: 'Cursos e Faculdades -',
            discount: 'Até 80%'
          },
        ],
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo CHRISAN.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o CHRISAN emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo CHRISAN é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de CHRISAN em Nova Iguaçu"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - CHRISAN ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "O CHRISAN se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Inspiration Paths Instituto Chrisan and the Revolution through Art and Education",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a Donation",
      },
      HEAD00002: {
        title: "CHRISAN",
        subtitle: "Exuding life!",
        button: "Learn more",
      },
      COMP00003: {
        title: "HOPE, SOCIAL INCLUSION AND TRANSFORMATION.",
        subTitle:
          `<p>In 2012, a bold and hopeful dream was born in Baixada Fluminense, aiming to transform lives and contribute to the construction of a more just and equal future. This realized dream is the Chrisan Institute. We are an institution committed to reducing social inequality.</p>
          <p>We strive daily to provide opportunities through artistic expression and meaningful learning. Today, our School of Arts is the epicenter of this transformation, designed to trigger a learning process, empowering each individual to shape their own destiny.</p>
          <p>We are recognized by CMAS, CMDCA, CNEAS, CEBAS, and proudly bear the DOAR and NGO Transparent (in the implementation phase) seals. These seals are proof of our commitment to transparency, integrity, and excellence in everything we do.</p>
          <p><strong>We expand horizons, enrich perspectives, and shape futures.</strong></p>
          `,
        button: "JOIN US AND TRANSFORM REALITIES.",
      },
      COMP00004: {
        title: "OUR NUMBERS",
        cards: [
          {
            top: "",
            span: "26",
            bottom: "Employees",
          },
          {
            top: "",
            span: "5",
            bottom: "trainees with a grant + college fees"
          },
          {
            top: "More than",
            span: "20",
            bottom: "Volunteers",
          },
          {
            top: "More than",
            span: "6",
            bottom: "Years of experience",
          },
        ],
        button: "JOIN OUR CAUSE! MAKE A DIFFERENCE TODAY WITH YOUR DONATION",
      },
      COMP00005: {
        title: "INSTITUTE GUIDELINES",
        cards: [
          {
            title: 'Mission',
            text: 'Exist by promoting the citizenship and social development of people in situations of social vulnerability as a whole.',
          },
          {
            title: 'Vision',
            text: 'To be recognized as an agent of transformation, appreciation and training by the community, public and private agents throughout the state of Rio de Janeiro.',
          },
        ],
        button: "Donate now"
      },
      COMP00008: {
        title: "INSTITUTE ACTIVITIES.",
        subTitle:
          `<p class='left'>Our activities aim not only to fill gaps but also to create opportunities for personal and community growth. Some initiatives that are at the center of our mission:
          </p>
          <ul>
            <li><p>All the institute's activities are 100% free</p></li>
            <li><p>Circus School</p></li>
            <li><p>Capoeira School</p></li>
            <li><p>Theater School</p></li>
            <li><p>Dance School</p></li>
            <li><p>Radio, Podcast and TV School</p></li>
            <li><p>Zumba</p></li>
            <li><p>Urban Dances</p></li>
            <li><p>Functional Gymnastics</p></li>
            <li><p>60+ Project</p></li>
            <li><p>Poetry Soiree</p></li>
          </ul>
          <p>Art and education are the tools that shape tomorrow.</p>
          <strong>WE TRANSFORM LIVES WITH ART AND EDUCATION | INCLUSION IN ACTION | SOWING HOPE</strong>
          `,
        button: "BE PART OF OUR POSITIVE IMPACT!",
      },
      COMP00017: {
        title: "<h2>INSTITUTE TIMELINE</h2>",
        subtitle: "<p class='subtitle'>Be part of the Chrisan Institute's history</p>",
        cards: [
          {
            title: "2016",
            text: "Launch of the Chrisan Institute with comprehensive projects, including capoeira, handicrafts, jiu-jitsu, and educational reinforcement classes."
          },
          {
            title: "2018",
            text: "Implementation of the food safety project and inclusion of zumba and theater activities.",
          },
          {
            title: "2019",
            text: "Establishment of a strategic partnership with the Children's Institute for the implementation of the \"Citizen Space\" course, expanding our educational impact.",
          },
          {
            title: "2020",
            text: "Challenging year with the onset of the pandemic, mobilizing efforts to raise 12 tons of food and deep diving into our internal documentation.",
          },
          {
            title: "2021",
            text: "Expansion from 8 to 21 projects during the pandemic. Director Otávio Oliveira is elected Social Entrepreneur of the Year in Brazil, in an audit conducted by the Children's Institute. Additional collection of 21 tons of food to combat hunger. Achievement of the first parliamentary amendment to strengthen our initiatives.",
          },
          {
            title: "2022",
            text: "Faced a challenging year being without headquarters. Achievement of the CEBAS certification, recognizing our dedication and efficiency in social activities.",
          },
          {
            title: "2023",
            text: "Year of renewal and achievements with the acquisition of a new headquarters for the Chrisan Institute.",
          },
        ],
      },
      COMP00018: {
        title: "Values",
        altText: "Photo of",
        cards: [
          {
            title: "Being People Who Like People",
            content: "We celebrate humanity in its diversity, promoting relationships based on respect, compassion, and empathy.",
          },
          {
            title: "Ethics",
            content: "We base our actions on solid ethical principles, ensuring that each initiative is conducted with integrity and responsibility.",
          },
          {
            title: "Transparency",
            content: "We maintain open channels of communication, ensuring that transparency is the foundation of all interactions, strengthening trust in our community."
          },
          {
            title: "Commitment",
            content: "We are committed to the cause we embrace, dedicating our efforts to create positive and lasting impact."
          },
          {
            title: "Honesty",
            content: "We act with honesty and frankness, recognizing that truth is essential for growth and mutual trust."
          },
          {
            title: "Integrity",
            content: "We maintain high standards of integrity in all our operations, ensuring that our actions reflect our core values."
          },
          {
            title: "Results-Oriented",
            content: "We seek not only to make a difference but to achieve tangible results that improve the lives of those we serve."
          },
        ],
        subtitle: "Commitment to Transparency and Excellence",
        button: "Donate now",
      },
      COMP00020: {
        title: "GOALS AND OBJECTIVES",
        subTitle: `At the Chrisan Institute, our vision for the future is as bold as our mission. We aim to expand our family, providing access to transformative opportunities offered by the Chrisan Institute. We want to be the virtual home of 30,000 curious minds, ready to explore, learn, and grow, expand our supporter base, cultivating 100 dedicated donors who share our vision of a fair and equal community.<br><br>
        <strong>We aim to be a symbol of hope, growth, and achievement.</strong>
        `,
        button: "Donate now",
      },
      COMP_ALLYA: {
        title: 'Join the ',
        description: `
          <p>Your donation is very important to us and makes all the difference. The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Whether your donation is big or small, know that it is proof of your commitment to a better world for everyone. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>CHRISAN</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>In Propago's Clube do Bem you will save money, make your day-to-day more practical and best of all, your savings can turn into a donation!</p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: 'Beauty and well-being! -',
            discount: 'Up to 80% OFF'
          },
          {
            area: 'Leisure - ',
            discount: 'Up to 50% OFF'
          },
          {
            area: 'Restaurants -',
            discount: 'Up to 35% OFF'
          },
          {
            area: 'Courses and Undergraduations -',
            discount: 'Up to 80% OFF'
          },
        ],
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does CHRISAN accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both CHRISAN and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of CHRISAN's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - CHRISAN ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "CHRISAN worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Senderos de Inspiración Instituto Chrisan y la Revolución a través del Arte y la Educación",
      },
      ButtonFloating: {
        button: "Donar Ahora",
        buttonBottom: "Haz una Donación",
      },
      HEAD00002: {
        title: "CHRISAN",
        subtitle: "¡Exudando vida!",
        button: "Más información",
      },
      COMP00003: {
        title: "ESPERANZA, INCLUSIÓN SOCIAL Y TRANSFORMACIÓN.",
        subTitle:
          `<p>En 2012, nació un sueño audaz y lleno de esperanza en la Baixada Fluminense, con la intención de transformar vidas y contribuir a la construcción de un futuro más justo e igualitario. Este sueño hecho realidad es el CHRISAN. Somos una institución comprometida con la reducción de la desigualdad social.</p>
          <p>Luchamos diariamente para ofrecer oportunidades a través de la expresión artística y del aprendizaje significativo. Hoy, nuestra Escuela de Artes es el epicentro de esta transformación, diseñada para desencadenar un proceso de aprendizaje, capacitando a cada individuo para dar forma a su propio destino.</p>
          <p>Somos reconocidos por CMAS, CMDCA, CNEAS, CEBAS, y portamos con honor los sellos DOAR y ONG Transparente (en fase de implementación). Estos sellos son prueba de nuestro compromiso con la transparencia, integridad y excelencia en todo lo que hacemos.</p>
          <p><strong>Expandimos horizontes, enriquecemos perspectivas y damos forma a futuros.</strong></p>
          `,
        button: "ÚNETE A NOSOTROS Y TRANSFORMA REALIDADES.",
      },
      COMP00004: {
        title: "NUESTROS NÚMEROS",
        cards: [
          {
            top: "",
            span: "26",
            bottom: "Empleados",
          },
          {
            top: "",
            span: "5",
            bottom: "becarios con beca + tasas universitarias",
          },
          {
            top: "Más de",
            span: "20",
            bottom: "Voluntarios",
          },
          {
            top: "Más de",
            span: "6",
            bottom: "Años de experiencia",
          },
        ],
        button: "ÚNETE A NUESTRA CAUSA. HAZ LA DIFERENCIA HOY CON TU DONACIÓN",
      },
      COMP00005: {
        title: "DIRECTRICES DEL INSTITUTO",
        cards: [
          {
            title: 'Misión',
            text: 'Existir promoviendo la ciudadanía y el desarrollo social de las personas en situación de vulnerabilidad social en su conjunto.',
          },
          {
            title: 'Visión',
            text: 'Ser reconocido como agente de transformación, valorización y capacitación por la comunidad, agentes públicos y privados de todo el estado de Río de Janeiro..',
          },
        ],
        button: "Dona ahora"
      },
      COMP00008: {
        title: "ACTIVIDADES DEL INSTITUTO.",
        subTitle:
          `<p class='left'>Nuestras actividades no solo buscan llenar vacíos, sino también crear oportunidades para el crecimiento personal y comunitario. Algunas iniciativas que están en el centro de nuestra misión:
          </p>
          <ul>
            <li><p>Todas las actividades del instituto son 100% gratuitas</p></li>
            <li><p>Escuela de Circo</p></li>
            <li><p>Escuela de Capoeira</p></li>
            <li><p>Escuela de Teatro</p></li>
            <li><p>Escuela de Danza</p></li>
            <li><p>Escuela de radio, podcast y TV</p></li>
            <li><p>Zumba</p></li>
            <li><p>Danzas Urbanas</p></li>
            <li><p>Gimnasia funcional</p></li>
            <li><p>Proyecto 60+</p></li>
            <li><p>Sarau de Poesías</p></li>
            <li><p>Acceso Gratuito y Regular:</p>Creemos en la democratización del acceso a la cultura, el arte y la educación.</li>
          </ul>
          <p>El arte y la educación son las herramientas que moldean el mañana.</p>
          <strong>TRANSFORMAMOS VIDAS CON ARTE Y EDUCACIÓN | INCLUSIÓN EN ACCIÓN | SEMBRANDO ESPERANZA</strong>
          `,
        button: "SÉ PARTE DE NUESTRO IMPACTO POSITIVO.",
      },
      COMP00017: {
        title: "<h2>LÍNEA DE TIEMPO DEL INSTITUTO</h2>",
        subtitle: "<p class='subtitle'>Forme parte de la historia del Instituto Chrisan</p>",
        cards: [
          {
            title: "2016",
            text: "Lanzamiento del CHRISAN con proyectos amplios, incluyendo capoeira, artesanía, jiu-jitsu y clases de refuerzo educativo."
          },
          {
            title: "2018",
            text: "Puesta en marcha del proyecto de seguridad alimentaria e inclusión de actividades de zumba y teatro.",
          },
          {
            title: "2019",
            text: "Establecimiento de una asociación estratégica con el Instituto de la Niñez para la implementación del curso \"Espacio Ciudadano\", ampliando nuestro impacto educativo.",
          },
          {
            title: "2020",
            text: "Año desafiante con el inicio de la pandemia, movilizando esfuerzos para recaudar 12 toneladas de alimentos e iniciar una inmersión profunda en nuestras documentaciones internas.",
          },
          {
            title: "2021",
            text: "Expansión de 8 a 21 proyectos durante la pandemia. El director Otávio Oliveira es elegido Emprendedor Social del Año en Brasil, en una auditoría realizada por el Instituto de la Niñez. Recaudación adicional de 21 toneladas de alimentos para combatir el hambre. Logro de la primera enmienda parlamentaria para fortalecer nuestras iniciativas.",
          },
          {
            title: "2022",
            text: "Enfrentamos un año difícil al quedarnos sin sede. Logro de la certificación CEBAS, reconociendo nuestra dedicación y eficiencia en las actividades sociales.",
          },
          {
            title: "2023",
            text: "Año de renovación y logros con la obtención de una nueva sede para el CHRISAN.\nAplicación del programa de prácticas.\nImplementation of the trainee program.",
          },
        ],
      },
      COMP00018: {
        title: "Valores",
        altText: "Foto de",
        cards: [
          {
            title: "Ser Gente que Quiere a la Gente",
            content: "Celebramos la humanidad en su diversidad, promoviendo relaciones basadas en el respeto, la compasión y la empatía.",
          },
          {
            title: "Ética",
            content: "Guiamos nuestras acciones por principios éticos sólidos, garantizando que cada iniciativa se lleve a cabo con integridad y responsabilidad.",
          },
          {
            title: "Transparencia",
            content: "Mantenemos canales abiertos de comunicación, asegurando que la transparencia sea la base de todas las interacciones, fortaleciendo la confianza en nuestra comunidad."
          },
          {
            title: "Compromiso",
            content: "Estamos comprometidos con la causa que abrazamos, dedicando nuestros esfuerzos a crear un impacto positivo y duradero."
          },
          {
            title: "Honestidad",
            content: "Actuamos con honestidad y franqueza, reconociendo que la verdad es esencial para el crecimiento y la confianza mutua."
          },
          {
            title: "Integridad",
            content: "Mantenemos estándares elevados de integridad en todas nuestras operaciones, garantizando que nuestras acciones reflejen nuestros valores fundamentales."
          },
          {
            title: "Enfoque en Resultados",
            content: "Buscamos no solo marcar la diferencia, sino alcanzar resultados tangibles que mejoren la vida de aquellos a quienes servimos."
          },
        ],
        subtitle: "Compromiso con la Transparencia y la Excelencia",
        button: "Dona ahora",
      },
      COMP00020: {
        title: "METAS Y OBJETIVOS",
        subTitle: `En el CHRISAN, nuestra visión para el futuro es tan audaz como nuestra misión. Buscamos ampliar nuestra familia, brindando acceso a oportunidades transformadoras ofrecidas por el CHRISAN. Queremos ser el hogar virtual de 30,000 mentes curiosas, listas para explorar, aprender y crecer, ampliar nuestra base de partidarios, cultivando 100 donantes dedicados que compartan nuestra visión de una comunidad más justa e igualitaria.<br><br>
        <strong>Buscamos ser un símbolo de esperanza, crecimiento y logro.</strong>
        `,
        button: "Dona ahora",
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>Su donación es muy importante para nosotros y marca la diferencia. El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Tanto si su donación es grande como pequeña, sepa que es una prueba de su compromiso con un mundo mejor para todos. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses a CHRISAN</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>Con el Clube do Bem de Propago ahorrarás dinero, harás tu día a día más práctico y lo mejor de todo, ¡tu ahorro puede convertirse en donación!</p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: 'Belleza y bienestar -',
            discount: 'Hasta el 80% de descuento',
          },
          {
            area: 'Ocio -',
            discount: 'Hasta el 50% de descuento',
          },
          {
            area: 'Restaurantes -',
            discount: 'Hasta el 35% de descuento',
          },
          {
            area: 'Cursos y Universidades -',
            discount: 'Hasta el 80% de descuento',
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿CHRISAN acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para CHRISAN como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de CHRISAN"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - CHRISAN® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "CHRISAN preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;