import { useState, lazy, Suspense } from "react";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";
import HEAD00002 from "./components/Wireframes/HEAD00002";

// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/chrisan/i18n";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy Loaded Components
const COMP00003 = lazy(() => import("./components/Wireframes/COMP00003"));
const COMP00004 = lazy(() => import("./components/Wireframes/COMP00004"));
const COMP00005 = lazy(() => import("./components/Wireframes/COMP00005"));
const COMP00008 = lazy(() => import("./components/Wireframes/COMP00008"));
const COMP00017 = lazy(() => import("./components/Wireframes/COMP00017"));
const COMP00018 = lazy(() => import("./components/Wireframes/COMP00018"));
const COMP00020 = lazy(() => import("./components/Wireframes/COMP00020"));
const COMP_NEWS = lazy(() => import("./components/Wireframes/COMP_NEWS"));
const COMP_GAL = lazy(() => import("./components/Wireframes/COMP_GAL"));
const COMP_ALLYA = lazy(() => import("./components/Wireframes/COMP_ALLYA"));
const CHEC00001 = lazy(() => import("./components/Wireframes/CHEC00001"));
const FAQS00001 = lazy(() => import("./components/Wireframes/FAQS00001"));
const COMP_GEOL = lazy(() => import("./components/Wireframes/COMP_GEOL"));
const FOOT00001 = lazy(() => import("./components/Wireframes/FOOT00001"));

const App = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  let dirName = 'chrisan';

  startI18N(i18nTranslations);

  return (
    <>
      <div className="lp-app notranslate">

        <HEAD00002 dirName={dirName} />
        <ButtonDonateNow
          primaryColor="#3BABB4"
          secondaryColor="#FFFFFF"
          borderFixedColor="#FFFFFF"
          borderFixedHoverColor="#3BABB4"
        />
        {SectionCookies != '' && acceptedCookies === false && (
          <SectionCookies onAccept={() => setAcceptedCookies(true)} />
        )}
        <Suspense>
          <COMP00003 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00008 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00005 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00018 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP_GAL dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00004 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00017 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00020 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP_NEWS url={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FInstitutoChrisan&tabs=timeline&width=600&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"} />
        </Suspense>
        <Suspense>
          <COMP_ALLYA />
        </Suspense>
        <Suspense>
          <CHEC00001 />
        </Suspense>
        <Suspense>
          <FAQS00001 />
        </Suspense>
        <Suspense>
          <COMP_GEOL url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.776583387569!2d-43.45877892454136!3d-22.73654403180918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9967b873a10db9%3A0x990668a9421a5dfb!2sR.%20Frei%20Frederico%20Vier%2C%20175%20-%20Alto%20da%20Posse%2C%20Nova%20Igua%C3%A7u%20-%20RJ%2C%2026022-830!5e0!3m2!1spt-BR!2sbr!4v1705522084310!5m2!1spt-BR!2sbr" />
        </Suspense>
        <Suspense>
          <FOOT00001 />
        </Suspense>
      </div>
    </>
  );
}

export default App;