import styled from "styled-components";

const Title = styled.h1`
    margin-top: 10px;
    color: ${({ colorValue }) => colorValue};
    line-height: 36px;
    font-size: 32px;
    font-weight: 900;
    text-align: center;
`; 

const HeaderTitle = ({ titleColor = '#000', children}) => {
    return <Title colorValue={titleColor}>{children}</Title>
}

export default HeaderTitle;