import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Utils
import { scrollTo } from "../../../utils/helperScrolls";

// Components
import Button from "../../Button";
import DropdownLocale from "../../DropdownLocale";
import HeaderTitle from "../../HeaderTitle";

// Styles
import { Wrapper, Color } from "./styles";

const Wireframe = ({ dirName }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      titleColor="#C42434"
      backgroundImageURL={`/assets/${dirName}/${Wireframe.displayName}-back.webp`}
    >
      <div>
        <div className="d-flex">
          <Color color="#3BABB4" />
          <Color color="#C42434" />
        </div>
        <div className="wrapper-button-i18n">
          <div className="d-flex justify-content-center">
            <Col xs={11} md={10} lg={10} xl={8}>
              <DropdownLocale />
            </Col>
          </div>
        </div>
        <div className="d-flex justify-content-center selo">
          <Col md={3} className="imageSelo">
            <Image
              src="/assets/SELO.webp"
              width={123}
              height={139}
              alt=''
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center logo">
          <Col xs={6} className="d-flex justify-content-center">
            <Image
              src={`/assets/${dirName}/${Wireframe.displayName}-logo.webp`}
              width={270}
              height={200}
              alt=''
              fluid
            />
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col
            xs={10}
            md={10}
            lg={8}
            xl={7}
          >
            <HeaderTitle titleColor="#C42434 ">
              {t(`${Wireframe.displayName}.title`)}
            </HeaderTitle>
          </Col>
        </div>
        <div className="d-flex justify-content-center ">
          <Col xs={10} md={8} lg={7} xl={6} className="text-center sub-title">
            {t(`${Wireframe.displayName}.subtitle`)}
          </Col>
        </div>
        <div className="d-flex justify-content-center button">
          <Button
            primaryColor="#C42434"
            secondaryColor="#FFFFFF"
            onClick={() => {
              scrollTo("COMP00003");
            }}
          >
            {t(`${Wireframe.displayName}.button`)}
          </Button>
        </div>
      </div>

    </Wrapper>
  );
};

Wireframe.displayName = "HEAD00002";
export default Wireframe;